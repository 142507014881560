import React from 'react';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';

import './../../../assets/scss/style.scss';
import Aux from "../../../hoc/_Aux";
import logoDark from '../../../assets/images/EmergeRemit.png';

import { Alert } from "reactstrap";
import { Button } from 'react-bootstrap';
import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { connect } from "react-redux";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker";
import moment from 'moment';

// LoadingOverlay.propTypes = undefined

import {
    setUserLoading,
    dismissUserError,
    createCustomerAccount
} from "../../../store/actions/ewallet/users";

const initStates = {
    isLoading: false,
    businessCode: "00002",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    gender: "",
    dateOfBirth: new Date(),
    phone: "",
    status: "progress",
    password: "",
    confirmpassword: "",
};

class SignUp1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = initStates
    }

    componentDidUpdate() {
        console.log(this.props)
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleDateChange = (e) => {
        this.setState({
            dateOfBirth: e
        })
    }

    handleDateSelect = (e) => {
        this.setState({
            dateOfBirth: e
        })
    }

    handleSubmit = (e, formData, inputs) => {

        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Confirm your action</h1>
                        <p>Are you sure you want to register this account?</p>
                        <Button onClick={onClose} className='btn-icon m-r-5' variant={'outline-secondary'}>Cancel</Button>
                        <Button
                            onClick={() => {

                                let params = queryString.parse(this.props.location.search)
                                if (params && params.ref) formData.referredByToken = params.ref;

                                formData.businessCode = this.state.businessCode;
                                formData.dateOfBirth = moment(this.state.dateOfBirth).format("YYYY-MM-DD");

                                formData.countryCode = "";
                                formData.countryFirstLevel = "";
                                formData.streetAddr = "";
                                formData.postcode = "";
                                formData.city = "";
                                formData.accountNumber = "";
                                formData.bankIban = "";
                                formData.bankName = "";
                                formData.bankCode = "";
                                formData.bankBic = "";
                                formData.documents = [];

                                this.props.setUserLoading("Processing Request...");
                                this.props.createCustomerAccount(formData);
                                // this.setState(initStates);
                                onClose();
                            }}
                            className='btn-icon' variant={'outline-info'}>Confirm</Button>
                    </div>
                );
            }
        });
    }

    render() {
        return (
            <Aux>
                <LoadingOverlay
                    active={this.props.loading}
                    spinner
                    text={"Account creation in progress..."}
                >
                    <div className="page-ath-wrap">
                        <div className="page-ath-content">
                            <div className="page-ath-header">
                                <a href="/" className="page-ath-logo"><img src={logoDark} alt="logo" /></a>
                            </div>
                            <div className="page-ath-form">
                                <h2 className="page-ath-heading">Sign Up <small>to have your EmergeRemit wallet account</small></h2>
                                <Alert color={'danger'} style={{ opacity: 100 }}
                                    isOpen={this.props.error}
                                    toggle={this.props.dismissError}>
                                    {this.props.errorMsg}
                                </Alert>
                                <Alert color={'success'} style={{ opacity: 100 }}
                                    isOpen={this.props.usertCreated}
                                    toggle={this.props.dismissError}>
                                    Account has successfully been created. A KYC process will be on progress.
                                </Alert>
                                <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}>
                                    <div className="input-item">
                                        <label htmlFor="firstName">Enter Email Address</label>
                                        <TextInput
                                            name="email"
                                            id="email"
                                            placeholder="Your Email"
                                            type="email"
                                            required
                                            errorMessage={{ required: "Username is required" }}
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <label htmlFor="firstName">Enter Phone Number</label>
                                        <TextInput
                                            name="phone"
                                            id="phone"
                                            placeholder="Your Phone Number"
                                            type="text"
                                            required
                                            errorMessage={{ required: "Phone number is required" }}
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <label htmlFor="firstName">Enter First name</label>
                                        <TextInput
                                            name="firstName"
                                            id="firstName"
                                            type="text"
                                            placeholder="First Name"
                                            required
                                            errorMessage={{ required: "First name is required" }}
                                            value={this.state.firstName}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <label htmlFor="middleName">Enter Middle name</label>
                                        <TextInput
                                            name="middleName"
                                            id="middleName"
                                            type="text"
                                            placeholder="Middle Name"
                                            value={this.state.middleName}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <label htmlFor="lastName">Enter Last name</label>
                                        <TextInput
                                            name="lastName"
                                            id="lastName"
                                            type="text"
                                            placeholder="Last Name"
                                            required
                                            errorMessage={{ required: "Last name is required" }}
                                            value={this.state.lastname}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <label htmlFor="gender">Select a Gender</label>
                                        <SelectGroup name="gender" id="gender"
                                            value={this.state.color}
                                            required errorMessage="Please select a Gender."
                                            onChange={this.handleChange}>
                                            <option value="">--- Please select ---</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </SelectGroup>
                                    </div>
                                    <div className="input-item">
                                        <label htmlFor="dateOfBirth">Select Date Of Birth</label>
                                        <div className="row col-md-12">
                                            <DatePicker className="input-bordered width-inherit" name='dateOfBirth' selected={this.state.dateOfBirth}
                                                onSelect={this.handleDateSelect}
                                                onChange={this.handleDateChange} />
                                        </div>
                                    </div>

                                    {
                                        this.props.loading ?
                                            <Button className="btn btn-primary btn-block" disabled>
                                                <span className="spinner-border spinner-border-sm mr-1" role="status" />Loading...
                                            </Button> :
                                            <Button type="submit" className="btn btn-primary btn-block">Register</Button>
                                    }
                                </ValidationForm>
                                <div className="gaps-2x"></div>
                                <div className="gaps-2x"></div>
                                <div className="form-note">Already have an account? <NavLink to="/auth/signin" className="f-w-400"><strong>Sign in here</strong></NavLink> </div>
                            </div>

                            <div className="page-ath-footer">
                                <ul className="footer-links">
                                    <li><a href="regular-page.html">Privacy Policy</a></li>
                                    <li><a href="regular-page.html">Terms</a></li>
                                    <li>&copy; 2020 EmergeRemit.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="page-ath-gfx">
                            <div className="w-100 d-flex justify-content-center">
                                {/* <div className="col-md-8 col-xl-5"><img src={athGfx} alt="image" /></div> */}
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.users,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserLoading: () => {
            dispatch(setUserLoading())
        },
        createCustomerAccount: objValues => {
            dispatch(createCustomerAccount(objValues));
        },
        dismissError: () => {
            dispatch(dismissUserError());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp1);